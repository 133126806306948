import { FC } from 'react';
import { Typography } from '@mui/material';

import Icon from '@/designSystem/Icon/Icon';

import useStyles from './styles';

type Props = {
  text: string;
};

const TaxReductionLabel: FC<Props> = ({ text }) => {
  const { classes } = useStyles();

  return (
    <Typography variant="bodyMd" component="span" className={classes.container}>
      {text}
      <Icon icon="marianne" size="medium" />
    </Typography>
  );
};

export default TaxReductionLabel;
